import https from '@/router/https'
//获取文旅订单详情
export const getOrderDetail = (params) => {
    return https.fetchGet('/mallapp/orderfictitious/userOrders', params);
}
//获取核销码
export const getBuyCode = (params) => {
    return https.fetchGet('/mallapp/orderfictitious/verificationCode', params);
}
//申请退款
export const getReturnMoney = (data) => {
    return https.fetchPost('/mallapp/orderfictitious/refund', data);
}