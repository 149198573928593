<template>
    <div class="main">
        <!--市井文旅服务订单详情导航栏--->
        <section class="top">
            <van-nav-bar title="产品详情" safe-area-inset-top z-index="1000" left-arrow :fixed="isTop"
                @click-left="onClickLeft" />
        </section>
        <!-- 正常订单状态 -->
        <section v-show="goodsDetail.status == '0'" class="willPay">
            <div class="willPayD1">
                <img v-show="goodsDetail.orderStatus == '0'" src="@/assets/img/willPay04.png" alt="" />
                <img v-show="goodsDetail.orderStatus == '1'" src="@/assets/img/list-icon.png" alt="" />
            </div>
            <div class="willPayD2">
                <p>{{ goodsDetail.orderStatus == '0' ? '待使用' : goodsDetail.orderStatus == '1' ? '已使用'
                    : goodsDetail.orderStatus == '2' ? '已过期' : '暂无状态' }}</p>
                <p class="willPayD2P">有效期：{{ goodsDetail.validity }}</p>
            </div>
        </section>
        <!-- 售后订单状态 -->
        <section v-show="goodsDetail.status != '0'" class="willPay">
            <div class="willPayD1">
            </div>
            <div class="willPayD2">
                <p>{{ goodsDetail.status == '1' ? '售后中' : goodsDetail.status == '2' ? '已退款'
                    : goodsDetail.status == '3' ? '已取消' : '暂无状态' }}</p>
                <p class="willPayD2P">有效期：{{ goodsDetail.validity }}</p>
            </div>
        </section>
        <!-- 商品信息 -->
        <section class="shopInfo">
            <div class="goodsInfo">
                <div class="goodsList">
                    <img class="goodsImg" :src="goodsDetail.picUrls" alt="暂无图片，请退出重试" />
                    <div class="goodsText">
                        <div>
                            <p>{{ goodsDetail.name || '--' }}</p>
                            <!-- <div class="size fontColor">
                                <span>规格：</span>
                                <span>x</span>
                            </div> -->
                        </div>
                        <div class="price">
                            <p class="price-p">{{ goodsPrice.length > 1 ? goodsPrice.join('|') : goodsPrice.length > 0 ?
                                goodsPrice[0] :
                                '---' }}</p>
                            <div v-show="goodsDetail.status == '0' && goodsDetail.orderStatus == '0'">
                                <!-- <button class="buy" @click="handlerUse"><span>申请退款</span></button> -->
                                <van-button @click="returnMoney" round size="small" color="#999999" hairline plain
                                    style="width: 2.2rem;"><span style="color: #000000;">申请退款</span></van-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 订单编号等信息 -->
        <section class="orderInfo">
            <div class="orderNum">
                <span>订单编号</span>
                <p class="orderNumP">
                    <span>{{ goodsDetail.id || '--' }}</span>
                    <span class="copy clipboard" @click="copyOrderId('.clipboard')"
                        :dataClipboard="goodsDetail.id">复制</span>
                </p>
            </div>
            <div class="orderNum">
                <span>付款时间</span>
                <span>{{ goodsDetail.payTime || '---' }}</span>
            </div>
            <div v-show="goodsDetail.orderStatus == '1'" class="orderNum">
                <span>使用时间</span>
                <span class="massage fontColor">{{ goodsDetail.useTime || '---' }}</span>
            </div>
            <div v-show="goodsDetail.status == '2'" class="orderNum">
                <span>退款时间</span>
                <span class="massage fontColor">{{ goodsDetail.refundTime || '---' }}</span>
            </div>
        </section>
        <!-- 到底提醒 -->
        <section class="footer">
            <!-- <p>——已经到底啦——</p> -->
        </section>
        <!-- 支付或取消订单 -->
        <section class="payment">
            <van-tabbar :fixed="isBottom">
                <div class="paymentBtn">
                    <img class="btnImg" @click="contactService" src="@/assets/img/kefu.png" alt="" />
                    <a class="btnA" ref="tels">客服</a>
                </div>
                <button v-show="goodsDetail.status == '0' && goodsDetail.orderStatus == '0'" class="buy"
                    @click="handlerUse"><span>使用</span></button>
            </van-tabbar>
        </section>
        <!-- 使用核销码 -->
        <van-popup class="popup" position="bottom" round v-model="show">
            <p class="popup-p1">核销码</p>
            <div class="codeQR" v-for="(item) in codeList" :key="item.code">
                <p class="codeQR-1">核销码<span v-show="item.status == '1'">(已使用)</span></p>
                <p class="clipboardP"><span>{{ item.code }}</span> &ensp; &ensp;<span class="clipboardSpan"
                        @click="copyOrderId('.clipboardSpan')" :dataClipboard="item.code"
                        v-show="item.status == '0'">复制</span></p>
                <p class="codeQR-2">有效期至：{{ codeDate }}</p>
            </div>
        </van-popup>
        <van-popup class="popup2" round v-model="isReturnShow">
            <div class="popup2-div">
                <p class="popup2-div-top">确认退款</p>
                <p class="popup2-div-center">您确认要申请退款吗？</p>
                <div class="popup2-div-bottom">
                    <div>

                        <van-button @click="closeReturn" round size="small" color="#E54320" hairline plain
                            style="width: 2.6rem;"><span style="color: #E54320;">取 消</span></van-button>
                    </div>
                    <div>

                        <van-button @click="confirmReturn" round size="small" color="#E54320" hairline
                            style="width: 2.6rem;"><span style="color: #ffffff;">确 认</span></van-button>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { Toast } from "vant";
import {
    getTelephone
} from "@/api/yundian/orderIdDetail/index.js";
import { getOrderDetail, getBuyCode, getReturnMoney } from '@/api/culturalTourism/index.js'
import ClipboardJS from "clipboard";
export default {
    data() {
        return {
            show: false,//核销码弹出层
            isReturnShow: false,//退款弹窗
            isTop: true,//顶部返回
            isBottom: true,//底部按钮
            goodsDetail: {},//商品信息
            telephoneNumber: '',//客服电话
            goodsPrice: [],//价格组合
            codeList: [],//核销码组合
            codeDate: ''
        };
    },
    beforeMount() {
        this.$store.commit("setSession", this.$route.query.session);
    },
    activated() {
    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        this.getPhone()
        this.getPageInfo()
    },

    methods: {
        //复制方法
        copyOrderId(dom) {
            // console.log(dom, '???');
            // 1. 绑定剪贴板操作方法(订单号)
            let clipboard = new ClipboardJS(dom, {
                text: function (trigger) {
                    // 2. trigger 就是点击的 dom 元素，所以就可以通过它来获取到它的属性 'dataClipboard' 的值了
                    // 3. 从这个方法返回的 string 就是会复制到剪贴板中的内容，所以可以复制任何内容了，也可以在这里处理一些逻辑
                    // 4. 我在这里就只是单纯的输出了事先绑定好的值
                    // console.log(trigger.getAttribute('dataClipboard'));
                    return trigger.getAttribute("dataClipboard");
                },
            });
            // 5. 当复制成功的时候提示复制成功
            clipboard.on("success", () => {
                // 还可以添加监听事件，如：复制成功后提示
                Toast.success("复制成功");
            });
        },
        getPageInfo() {

            let id = this.$route.query.id
            // let id = '1712379382959452161'
            getOrderDetail({ id: id }).then(res => {
                if (res.data.code == 0) {
                    console.log(res.data.data);
                    this.goodsDetail = res.data.data
                    this.goodsPrice = []
                    if (this.goodsDetail.price && this.goodsDetail.price > 0) {
                        this.goodsPrice.push('￥' + this.goodsDetail.price)
                    }
                    if (this.goodsDetail.postHorse && this.goodsDetail.postHorse > 0) {
                        this.goodsPrice.push(this.goodsDetail.postHorse + '驿马')
                    }
                    if (this.goodsDetail.officialCertificate && this.goodsDetail.officialCertificate > 0) {
                        this.goodsPrice.push(this.goodsDetail.officialCertificate + '官凭')
                    }
                    if (this.goodsDetail.goldLeaf && this.goodsDetail.goldLeaf > 0) {
                        this.goodsPrice.push(this.goodsDetail.goldLeaf + '金叶子')
                    }
                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast.fail('请求失败，请退出重试')
            })
        },
        //回退
        onClickLeft() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.close.postMessage("up");
            }
            if (isAndroid) {
                window.android.close();
            }
        },
        //原生协议对接
        sendToPhone(type) {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (type == '0') {

                if (isIOS) {
                    window.webkit.messageHandlers.culturalTourismRefund.postMessage("888");
                }
                if (isAndroid) {
                    window.android.culturalTourismRefund();
                }
            }
        },
        //联系客服
        contactService() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                this.$refs.tels.href = 'tel://' + this.telephoneNumber;
                this.$refs.tels.click();
            }
            if (isAndroid) {
                window.android.callPhone('' + this.telephoneNumber);
            }
        },
        //获取客服电话
        getPhone() {
            getTelephone().then(res => {
                if (res.data.code == 0) {
                    this.telephoneNumber = res.data.data
                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast('客服繁忙中')
            })
        },
        //去使用(核销码)
        handlerUse() {
            getBuyCode({ id: this.goodsDetail.id }).then(res => {
                if (res.data.code == 0) {
                    // console.log(res.data.data);
                    this.codeList = res.data.data.list
                    this.codeDate = res.data.data.date
                    // console.log(this.codeDate, '777');
                    this.show = true
                } else {
                    Toast(res.data.message)
                }
            }).catch(() => {
                Toast('核销码获取失败')
            })
        },
        beforeClose(action, done) {
            if (action === 'confirm') {
                setTimeout(done, 1000);
            } else {
                done();
            }
        },
        //申请退款弹出
        returnMoney() {
            this.isReturnShow = true
        },
        //取消退款
        closeReturn() {
            this.isReturnShow = false
        },
        //确认退款
        confirmReturn() {
            getReturnMoney({ id: this.$route.query.id }).then(res => {
                if (res.data.code == 0) {
                    Toast.success('退款申请成功')
                    this.isReturnShow = false
                    this.sendToPhone('0')
                    this.getPageInfo()
                } else {

                    Toast.fail(res.data.message)
                }
            }).catch(() => {
                Toast('退款失败，请联系管理员')
            })
        }
    }
};
</script>
  
<style lang="less" scoped>
.main {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .popup {
        box-sizing: border-box;
        padding: 20px 20px;

        .popup-p1 {
            font-size: 16px;
            text-align: center;
        }

        .codeQR {
            padding: 10px;
            margin: 10px 0;
            background: #F6F6F6;

            .codeQR-1 {
                span {
                    color: #666666;
                    font-size: 12px;
                }
            }

            .clipboardP {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0;
                font-size: 14px;

                .clipboardSpan {
                    color: #E54320;
                    text-decoration: underline;
                }
            }

            .codeQR-2 {
                font-size: 12px;
                color: #999999;
            }
        }
    }

    .popup2 {
        width: 70%;
        height: 18%;

        &-div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &-top {
                margin: 10px;
                font-size: 16px;
                color: #832604;
            }

            &-bottom {
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }

    section {
        background: #fff;
        width: 100%;
        box-sizing: border-box;
    }



    .fontColor {
        line-height: 0.7784rem;
        color: #666666;
        font-size: 0.3459rem;
    }

    .top {
        height: 6%;
        font-size: 1.2973rem;
    }

    .willPay {
        display: flex;
        padding: 0.4324rem 0.4324rem 0.6rem 0.4324rem;
        border-bottom: 1px solid #E5E5E5;

        .willPayD1 {
            width: 1rem;
            display: flex;
            align-items: center;
        }

        .willPayD2 {
            .willPayD2P {

                color: #999999;
            }
        }

        img {
            height: 0.6rem;
            width: 0.6rem;
            margin-right: 0.3rem;
        }
    }

    .shopInfo {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 0.5135rem;

        .goodsInfo {
            display: flex;
            flex-flow: column nowrap;
            padding: 0.4324rem 0;
            border-bottom: 1px solid rgba(170, 170, 170, .4);

            .goodsList {
                display: flex;
                flex-flow: row nowrap;

                .goodsImg {
                    width: 3rem;
                    height: 3rem;
                }

                .goodsText {
                    margin-left: 10px;
                    flex: 1;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .price {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .price-p {
                            color: #E54320;
                            font-size: 12px;
                        }
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .returnGoods {
                padding: 0.4324rem 0 0 0;
                display: flex;
                justify-content: flex-end;

                button {
                    max-width: 2.8rem;
                    min-width: 2.4rem;
                    height: 0.8649rem;
                    margin-right: 0.6919rem;
                    border: 1px solid rgb(202, 202, 202);
                    background: #fff;
                    border-radius: 0.4324rem;
                }

                .buy {
                    background: #E54320;
                    color: #fff;
                    border: none;
                }
            }

            .otherText {
                padding-top: 0.4324rem;

                .integrate {
                    display: flex;
                    justify-content: space-between;
                }

                .freight {
                    display: flex;
                    justify-content: space-between;
                }



                .remark {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

    }

    .orderInfo {
        padding: 0.4324rem 0.5135rem;
        line-height: 0.7568rem;

        .orderNum {
            display: flex;
            justify-content: space-between;

            .orderNumP {
                display: flex;
                align-items: center;
                justify-content: end;
            }

            .copy {
                color: #E54320;
                margin-left: 0.2595rem;
                text-decoration: underline;
            }

            .massage {
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -moz-box;
                -moz-line-clamp: 2;
                -moz-box-orient: vertical;
                overflow-wrap: break-word;
                word-break: break-all;
                white-space: normal;
                overflow: hidden;
            }
        }
    }

    .payment {
        .van-tabbar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .paymentBtn {
                margin-left: 15px;
                padding: 15px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                .btnImg {
                    width: 0.6rem
                }

                .btnA {
                    color: #000;
                    font-size: 12px;
                }
            }

            button {
                width: 2.5946rem;
                height: 0.8649rem;
                margin-right: 0.6919rem;
                border: 1px solid #999999;
                background: #fff;
                border-radius: 0.4324rem;
            }

            .buy {
                background: #E54320;
                color: #fff;
                border: none;
            }

            .evaluate {
                border: none;
                background: #E54320;
                color: #fff;
            }

        }

    }

    .footer {
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}
</style>
  